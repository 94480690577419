<template>

    <section id="bio" class="main style1 special">
        <div class="container">
            <div class="row gtr-150">
                <div class="col-4 col-12-medium">
                    <img src="images/cabinet/1.jpg" class="portrait"/>
                </div>
                <div class="col-4 col-12-medium">
                    <img src="images/cabinet/2.jpg" class="portrait"/>
                </div>
                <div class="col-4 col-12-medium">
                    <img src="images/cabinet/3.jpg" class="portrait"/>
                </div>
            </div>
        </div>
    </section>


</template>
