<template>
    <section id="news" class="main style1 special">
        <div class="container">
            <div class="row gtr-150">
                <div class="col-12">
                  <h2>Actualités</h2>
                </div>
                <div class="col-6 col-12-medium" style="padding-bottom:3em;">
                  <a href="documents/FlyerNeuronutrition.pdf" target="_blank">Programme de neuro-nutrition</a><br><br>
                  <a href="documents/FlyerNeuronutrition.pdf" target="_blank">
                    <img src="documents/FlyerNeuronutrition.png" height="200"/>
                  </a><br>
                  <a href="documents/FlyerNeuronutrition.pdf" target="_blank">Cliquez ici</a>
                </div>
                <div class="col-6 col-12-medium">
                  <a href="documents/FlyerGestionDePoids.pdf" target="_blank">Programme de gestion et stabilisation de poids</a><br><br>
                  <video height="200" controls="">
                    <source src="videos/intro.mp4" type="video/mp4">
                  </video>
                  <a href="documents/FlyerGestionDePoids.pdf" target="_blank">
                    <img src="documents/FlyerGestionDePoids.png" height="200"/>
                  </a><br>
                  <a href="documents/FlyerGestionDePoids.pdf" target="_blank">Cliquez ici</a>
                </div>
            </div>
        </div>
    </section>
    <section id="four" class="main style2 special">
        <div class="container">
        </div>
    </section>
</template>
