<template>

    <!-- Footer -->
    <section id="footer">
        <div class="container">
            <div class="row gtr-150">
                <div class="col-4 col-12-medium">
                  <p>Elodie Auberson<br>
                    Place du Collège 7<br>
                    1375 Penthéréaz<br><br>
                  </p>
                </div>
                <div class="col-4 col-12-medium social-icons">
                    <ul class="icons">
                        <!-- <li><a href="#" class="icon brands alt fa-twitter"><span class="label">Twitter</span></a></li> -->
                        <li><a href="https://www.facebook.com/Elodie-Nutrition-113803473595350" target="_blank" class="icon brands alt fa-facebook-square"><span class="label">Facebook</span></a></li>
                        <li><a href="https://www.linkedin.com/in/elodie-auberson-440aa599/" target="_blank" class="icon brands alt fab fa-linkedin"><span class="label">LinkedIn</span></a></li>
                        <!-- <li><a href="#" class="icon brands alt fa-instagram"><span class="label">Instagram</span></a></li> -->
                        <!-- <li><a href="#" class="icon brands alt fa-github"><span class="label">GitHub</span></a></li> -->
                        <li><a href="mailto:elodie@ankyra.ch" class="icon solid alt fa-envelope"><span class="label">Email</span></a></li>
                    </ul>

                </div>
                <div class="col-4 col-12-medium">
                  <p>
                      +41 79 833 57 15<br>
                      elodie@ankyra.ch
                  </p>
                </div>
            </div>
        </div>

        <ul class="copyright">
            <li>&copy; Ankyra - v{{ state.appVersion }}</li>
        </ul>
        
    </section>
</template>

<script>
import { version } from "../../../package.json"

import { reactive } from 'vue'
import { onMounted } from 'vue'

export default {

    setup() {

        const state = reactive({
            appVersion: '0',
        })

        onMounted(() => {
            state.appVersion = version
        })

        return {
            state,
        }
    }
}
</script>
