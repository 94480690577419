<template>

    <section id="header" class="new">
        <div class="inner">
            <img src="images/logo_nutrition.png" width="600"/>
        </div>
    <!--
        <p class="proverb">
            «&nbsp;Que ton aliment soit ta seule médecine&nbsp;»<br>
            Hippocrate
        </p>
        <p class="address">
            Rte&nbsp;d'Yverdon&nbsp;22, 1373&nbsp;Chavornay, +41&nbsp;79&nbsp;833&nbsp;57&nbsp;15, elodie@ankyra.ch
        </p>
    -->
    </section>

</template>
