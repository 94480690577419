<template>

    <section id="bio" class="main style1 special">
        <div class="container">
            <div class="row gtr-150">
                <div class="col-6 col-12-medium">
                  <p>Ma philosophie consiste à croire en la capacité de chaque personne à prendre soin de soi. Je m’appelle Elodie Auberson, je suis maman de deux enfants et je suis passionnée par la nutrition.</p>

                  <p>C’est en ayant grandi avec des personnes atteintes de maladies dans mon entourage que j’ai peu à peu compris que l’alimentation jouait un rôle considérable sur la santé. J’ai alors entrepris une formation en nutrition, en micronutrition ainsi qu’en naturopathie et je pratique l’alimentation vivante pour pouvoir proposer un accompagnement global de chaque individu. C’est en conjuguant la théorie, la pratique et l’expérience au travers de ma famille que l’alimentation est devenue un de mes piliers de vie.</p>

                  <p>Ankyra est ainsi né et vous accompagne dans votre démarche pour devenir acteur de votre santé, tout en respectant votre individualité. En passant par le contenu de votre assiette, que ce soit pour cause de maladie, de gestion de poids, ou si vous aspirez à un meilleur équilibre de vie, Ankyra vous aidera à faire de l’alimentation votre meilleure alliée de santé.</p>

                  <p style="font-style: italic; margin-bottom: 0;">« La force qui est en chacun de nous, est notre plus grand médecin. » Hippocrate</p>
                </div>
                <div class="col-6 col-12-medium">
                    <img src="images/portrait.jpg" class="portrait"/>
                </div>
            </div>
        </div>
    </section>

</template>
